import {modal} from "../utils/Modal";

export class ApplicationListWorkflow {

  constructor() {
    this.initEvents();
  }

  initEvents() {
    $('[data-remove-association]').click(event => this.onRemoveAssociationClick(event));
  }

  onRemoveAssociationClick(event) {
    event.preventDefault();
    modal.load($(event.target).attr('href'), function ($modal) {
      $modal.filter('.modal').modal('show');
    });
  }


}
