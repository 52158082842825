import {OfferSelector} from "./OfferSelector";
import {PaymentOption} from "./PaymentOption";
import {loader} from "../utils/Loading";
import {messages} from "../utils/Messaging";
import {Ga} from "../utils";

export class PurchaseWorkFlow {

  constructor(params) {
    this.paymentOptionsSelector = '[data-payment-option]';
    this.transactionStatus = params.transactionStatus;

    this.initEvents();
    this.offerSelector = new OfferSelector();

    if (params.ga) {
      this.ga = params.ga;
    } else {
      this.ga = new Ga();
    }

    this.gaLocation = params.gaLocation;
    this.ga.sendVirtualPageView(this.gaLocation);

    this.initPaymentOptions();
  }

  initEvents() {
    $(this.paymentOptionsSelector).click(event => this.onPaymentOptionClick(event));
    $('[data-offer-option]').click(event => this.onOfferOptionClick(event));
    messages.on('payment-finalized', response => this.onPaymentFinalized(response));
  }

  initPaymentOptions() {
    this.paymentOptions = [];
    $(this.paymentOptionsSelector).each((index, element) => {
      let params = this._getPaymentOptionParams($(element));
      this.paymentOptions.push(new PaymentOption(params));
    });

    let $paymentOptionsForOffer = $(`[data-option-for-offer='${this.offerSelector.selectedOffer}']`).find(this.paymentOptionsSelector);
    let $firstPaymentOptionForOffer = $paymentOptionsForOffer.first();
    if ($firstPaymentOptionForOffer && $firstPaymentOptionForOffer.data('type') !== 'wallet') {
      this._showPaymentOption($firstPaymentOptionForOffer, {scroll: false, hideButton: $paymentOptionsForOffer.length === 1});
    }
  }

  _getPaymentOptionParams($element) {
    return {
      id: $element.attr('id'),
      url: $element.attr('href'),
      hasTemplate: $element.data('has-template') === true,
      template: $element.data('template-name'),
      checkTransactionStatus: $element.data('check-transaction-status') === true,
      gaLocation: $element.data('ga-location'),
      cssProperties: $element.data('css-properties'),
      reload: $element.data('reload') === true,
      ga: this.ga,
      transactionStatus: this.transactionStatus,
      amount: $element.data('amount'),
      currency: $element.data('currency'),
      amountMinorUnits: $element.data('amount-minor-units'),
      label: $element.data('label'),
      description: $element.data('description'),
      isSubscription: $element.data('purchase-type') === 'subscription',
    }
  }

  onPaymentOptionClick(event) {
    event.preventDefault();
    let $currentTarget = $(event.currentTarget);
    this._showPaymentOption($currentTarget);
  }

  onOfferOptionClick(event) {
    let $currentTarget = $(event.currentTarget);
    let selectedOffer = $currentTarget.val();

    if (selectedOffer) {
      let $paymentOptionsForOffer = $(`[data-option-for-offer='${selectedOffer}']`).find(this.paymentOptionsSelector);
      let $firstPaymentOptionForOffer = $paymentOptionsForOffer.first();
      if ($firstPaymentOptionForOffer) {
        this._showPaymentOption($firstPaymentOptionForOffer, {hideButton: $paymentOptionsForOffer.length === 1});
      }
    } else {
      loader.show('main');
      document.location.href = $currentTarget.data('offer-option-url');
    }
  }

  _showPaymentOption($element, {scroll = true, hideButton = false} = {}) {
    let selectedPaymentOption = null;

    for (let paymentOption of this.paymentOptions) {
      if (paymentOption.$element.is($element)) {
        selectedPaymentOption = paymentOption;
      } else {
        paymentOption.hide();
      }
    }
    if (selectedPaymentOption) {
      if (scroll === true) {
        let $scrollTo = $('#payment-selection');
        if ($scrollTo.length > 0) {
          $scrollTo[0].scrollIntoView();
        }
      }

      selectedPaymentOption.show();

      if (hideButton === true) {
        selectedPaymentOption.$element.hide();
      }

      if (selectedPaymentOption.checkTransactionStatus && !this.transactionStatus.isStarted) {
        this.transactionStatus.start().then(response => {
          loader.show('main');
          document.location.href = response.url;
        });
      }
    }
  }

  onPaymentFinalized(response) {
    loader.show('main');
  }
}
