import {loader} from "../utils/Loading";
import {AccountExists, Ga, ReCaptchaManager, SimpleAjaxFormWorkflow} from "../utils";

export class LoginWorkFlow extends SimpleAjaxFormWorkflow {

  constructor(params) {
    super("login-form");
    this.$email = $('[data-username-field]');
    this.$success = this.$form.find('[data-form-success-container]');

    this._accountConfirmationUrl = params.accountConfirmationUrl;

    this.accountChecker = new AccountExists(params.accountCheckUrl, this.$email);
    this.translation = params.translation;

    this.waitForEmail();

    this.captchaManager = new ReCaptchaManager({
      inputName: "captcha",
    });

    if (params.ga.location) {
      this.ga = new Ga({getRemoteTracker: params.ga.getRemoteTracker});
      this.gaLocation = params.ga.location;
      this.ga.sendVirtualPageView(this.gaLocation);
    }
  }

  onFormSuccess(data) {
    loader.show('main');
  }

  onFormError(data, response) {
    this.clearSuccessContainer();
    $('[data-user-create-confirm]').click(event => this.onUserCreateConfirmClick(event));
    this.captchaManager.init();
  }

  onAjaxError() {
    this.onFormError();
    this.captchaManager.init();
  }

  waitForEmail() {
    this.accountChecker.start().then(response => {
      if (!this.accountChecker.accountValidated) {
        this.$form.cleanFormErrors();
        let currentValue = this.$email.val();

        let sendAccountConfirmationLink = document.createElement('a');
        sendAccountConfirmationLink.appendChild(document.createTextNode(this.translation.sendLinkAgain));
        sendAccountConfirmationLink.setAttribute('href', this._accountConfirmationUrl);
        sendAccountConfirmationLink.addEventListener('click', event => {
          event.preventDefault();
          $.post($(event.currentTarget).attr('href'), {'email': currentValue}).done(response => {
            if (response.success) {
              this.displaySuccessContainer(response.message);
            }
          });
        });
        this.$form.setFormError([`${this.translation.accountConfirmation} '${currentValue}'. `]);
        this.$form.find('[data-form-error-container]').find('.alert').append(sendAccountConfirmationLink);

      }
      this.waitForEmail();
    });
  }

  clearSuccessContainer() {
    this.$success.html('');
  }

  displaySuccessContainer(content) {
    this.$form.cleanFormErrors();
    let alert_html = `<div class="alert alert-success" role="alert" data-role="form-success">${content}</div>`;
    this.$success.append(alert_html);
  }

  // onSendMagicLink(event) {
  //   event.preventDefault();
  //   modal.load($(event.target).attr('href'), function ($modal) {
  //     const $form = $modal.find('form');
  //     $form.handleLightAjaxSubmit({
  //       onResponse: function (success, data) {
  //         if (success) {
  //           $form.find('[data-success-form]').show();
  //           $form.find('[data-role=submit]').hide();
  //         }
  //       }
  //     });
  //
  //     $form.find('[data-input-mail]').click(function (event) {
  //       let $successMessageContainer = $form.find('[data-success-form]');
  //       if ($successMessageContainer.is(':visible')) {
  //         $successMessageContainer.hide();
  //         $form.find('[data-role=submit]').show();
  //       }
  //     });
  //     $modal.filter('.modal').modal('show');
  //   });
  // }

  onUserCreateConfirmClick(event) {
    event.preventDefault();
    $.get($(event.currentTarget).attr('href')).done(response => {
      if (response.success) {
        this.displaySuccessContainer(response.message);
      }
    });
  }

}
