import {B2BillWorkflow} from "./B2BillWorkflow";
import {SecurionPayWorkflow} from "./SecurionPayWorkflow";
import {CentrobillWorkflow} from "./CentrobillWorkflow";
import {EpochWorkflow} from "./EpochWorkflow";
import {MobiyoWorkflow} from "./MobiyoWorkflow";
import {Audiotel} from "./Audiotel";
import {SmartCall} from "./SmartCall";
import {CCBillWorkflow} from "./CCBillWorkflow";
import {DimocoWorkflow} from "./DimocoWorkflow";
import {JimizzWorkflow} from "./JimizzWorkflow";
import {RocketgateWorkflow} from "./RocketgateWorkflow";
import { SegpayWorkflow } from "./SegpayWorkflow";
import { DummyWorkflow } from "./DummyWorkflow";

export class PaymentBackendsManager {
  constructor(transactionStatus) {
    this.loadedBackends = {};
    this.transactionStatus = transactionStatus;
  }

  load(backend, params) {
    let backendHandler;

    const id = `${backend}__${params.formId || "default"}`;

    console.log("LOAD BACKEND", backend);

    if (!this.loadedBackends[id]) {

      const parametersElement = window.document.querySelector("[data-backends-parameters]");

      if (parametersElement) {
        const url3DSecure = parametersElement.dataset.backendsThreedsUrl;
        const theme = parametersElement.dataset.backendsTheme;

        switch (backend) {
          case "dalenys":
            backendHandler = new B2BillWorkflow({
              APIKey: parametersElement.dataset.b2billApiKeyId,
              APISecret: parametersElement.dataset.b2billApiKey,
              transactionStatus: this.transactionStatus,
              url3DSecure: url3DSecure,
              theme: theme,
              cardCapture: params.cardCapture === true
            });
            break;
          case "securionpay":
            backendHandler = new SecurionPayWorkflow({
              APIKey: parametersElement.dataset.securionpayApiKey,
              transactionStatus: this.transactionStatus,
              cardCapture: params.cardCapture === true
            });
            break;
          case "centrobill":
            backendHandler = new CentrobillWorkflow({
              autoPost: params.autoPost !== false
            });
            break;
          case "epoch":
            backendHandler = new EpochWorkflow({
              autoPost: params.autoPost !== false
            });
            break;
          case "ccbill":
            backendHandler = new CCBillWorkflow({
              autoPost: params.autoPost !== false
            });
            break;
          case "dimoco":
            backendHandler = new DimocoWorkflow({
              APIKey: parametersElement.dataset.dimocoApiKey,
              transactionStatus: this.transactionStatus,
              autoPost: params.autoPost !== false
            });
            break;
          case "hipay":
            backendHandler = new MobiyoWorkflow({
              autoPost: params.autoPost !== false
            });
            break;
          case "audiotel":
            backendHandler = new Audiotel("code-validation-form");
            break;
          case "smartcall":
            backendHandler = new SmartCall({
              transactionStatus: this.transactionStatus
            });
            break;
          case "jimizz":
            backendHandler = new JimizzWorkflow({
              autoPost: params.autoPost !== false
            });
            break;
          case "rocketgate":
            backendHandler = new RocketgateWorkflow({
              autoPost: params.autoPost !== false
            });
            break;
          case "dummy":
              backendHandler = new DummyWorkflow({
                autoPost: params.autoPost !== false
              });
              break;
          case "segpay":
              backendHandler = new SegpayWorkflow({
                autoPost: params.autoPost !== false
              });
              break;
          default:
            break;
        }

        if (backendHandler) {
          this.loadedBackends[id] = backendHandler;
        }
      }
    } else {
      backendHandler = this.loadedBackends[id];
    }

    if (backendHandler && typeof backendHandler.init === "function") {
      backendHandler.init({
        amount: params.amount,
        amountInMinorUnits: params.amountInMinorUnits,
        currency: params.currency,
        urlParams: params.urlParams
      });
    }

    return backendHandler;
  }

}
