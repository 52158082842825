import {messages} from "../utils/Messaging";
import {loader} from "../utils/Loading";
import {CSRFHandler, DropdownHandler, DropdownHandlerV2, LanguageUpdate} from "../utils";
import {disabler} from "../utils/Disabler";


export class PaymentsBase {
  constructor(params) {
    this.$close = $('[data-close-iframe]');

    if (params.version === "v2") {
      new DropdownHandlerV2();
    } else {
      new DropdownHandler();
    }
    
    new CSRFHandler();
    new LanguageUpdate({
      url: params.languageUrl
    });

    this.initEvents();
  }

  initEvents() {
    this.$close.click(event => this.onCloseClick(event));
    $('[data-loading]').click(event => this.onLoadingClick(event));
    disabler.initDisablers();
  }

  onCloseClick(event) {
    event.preventDefault();
    if (this.$close.data('redirect')) {
      document.location.href = this.$close.data('redirect');
    } else {
      messages.sendMessage('close-wallet', {target: 'top'});
    }
  }

  onLoadingClick(event) {
    let $currentTarget = $(event.currentTarget);
    if ($currentTarget.attr('disabled') !== 'disabled') {
      loader.show($currentTarget.data('loading'));
    }
  }
}
