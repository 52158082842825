import {loader} from "../utils/Loading";
import {disabler} from "../utils/Disabler";
import {cookies} from "../utils/Cookies";

export class CrossSellsListManager {
  constructor(redirectUrl) {
    this.redirectUrl = redirectUrl;

    this._successTimeout = null;

    if (!this.redirectUrl) {
      throw new Error("No redirect url for cross sell view");
    }

    window.document.querySelectorAll(`[data-submit-payment]`).forEach((element) => {
      element.addEventListener("click", (event) => {
        event.preventDefault();
        const currentTarget = event.currentTarget;
        const url = currentTarget.dataset.submitPayment || currentTarget.closest("[data-submit-payment]").dataset.submitPayment;
        const offerId = currentTarget.closest(`[data-offer-id]`).dataset.offerId;
        this.submitPayment(offerId, url);
      });
    });

    window.document.querySelectorAll(`[data-open-offer-modal]`).forEach((element) => {
      element.addEventListener("click", (event) => {
        const currentTarget = event.currentTarget;
        const offerId = currentTarget.dataset.openOfferModal || currentTarget.closest("[data-open-offer-modal]").dataset.openOfferModal;
        this.displayOfferModal(offerId);
      });
    });

    window.document.querySelectorAll(`[data-offer-modal="close"]`).forEach((element) => {
      element.addEventListener("click", (event) => {
        this.closeOfferModal();
      });
    });
  }

  submitPayment(offerId, url) {
    if (url) {
      loader.show(`submit-payment-${offerId}`);
      disabler.disable("cross-sell");

      const errorContainer = window.document.querySelector(`[data-offer-error="${offerId}"]`);

      if (errorContainer) {
        errorContainer.style.display = "none";
      }

      const headers = new Headers();
      const token = cookies.get("csrftoken");
      headers.set("X-CSRFToken", token);
      headers.set("X-Requested-With", "XMLHttpRequest");

      const request = new Request(url, {
        method: "POST",
        headers: headers,
      });

      fetch(request).then((response) => {
        const contentType = response.headers.get("content-type");
        if (response.ok && contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => {
            if (data["success"]) {
              this.onPaymentSuccess(offerId, data["message"]);
            } else {
              this.onPaymentError(offerId, data);
            }
          });
        } else {
          this.onPaymentError(offerId);
        }

      }).catch(() => {
        this.onPaymentError(offerId);
      });
    }
  }

  onPaymentError(offerId, data) {
    loader.hide(`submit-payment-${offerId}`);
    disabler.enable("cross-sell");

    let error = "";
    const defaultError = window.document.querySelector(`[data-default-error]`);

    if (data && data["error"] && data["error"]["message"]) {
      error = data["error"]["message"];
    } else if (defaultError) {
      error = defaultError.dataset.defaultError;
    }

    const errorContainer = window.document.querySelector(`[data-offer-error="${offerId}"]`);

    if (errorContainer && error) {
      errorContainer.innerHTML = error;
      errorContainer.style.display = errorContainer.dataset.displayMode || "block";
    }
  }

  onPaymentSuccess(offerId, message) {
    let redirect = !offerId;

    if (!redirect) {
      this.deleteOffer(offerId);
      redirect = window.document.querySelectorAll(`[data-offer-item="offer"]`).length === 0;
    }

    if (redirect) {
      window.location.href = this.redirectUrl;
    } else {
      loader.hide("main");
      loader.hide(`submit-payment-${offerId}`);
      disabler.enable("cross-sell");
      this.alertSuccess(message);
    }
  }

  displayOfferModal(offerId) {
    if (this.successContainer) {
      this.successContainer.style.display = "none";
    }

    const modal = window.document.querySelector(`[data-offer-item="modal"][data-offer-id="${offerId}"]`);

    if (modal) {
      modal.style.display = modal.dataset.displayMode || "block";

      const backdrop = this.modalsBackdrop;

      if (backdrop) {
        backdrop.style.display = modal.dataset.displayMode || "block";
      }
    }
  }

  closeOfferModal() {
    const backdrop = this.modalsBackdrop;

    if (backdrop) {
      backdrop.style.display = "none";
    }

    window.document.querySelectorAll(`[data-offer-item="modal"]`).forEach((modal) => {
      modal.style.display = "none";
    });
  }

  deleteOffer(offerId) {
    this.closeOfferModal(offerId);

    window.document.querySelectorAll(`[data-offer-id="${offerId}"]`).forEach((element) => {
      element.remove();
    });
  }

  alertSuccess(message) {
    if (this.successContainer && message) {
      const text = this.successContainer.querySelector(`[data-offer-success="text"]`);

      if (text) {
        text.innerHTML = message;
        this.successContainer.style.display = this.successContainer.dataset.displayMode || "block";

        this.clearSuccessTimeout();

        this._successTimeout = setTimeout(() => {
          this.successContainer.style.display = "none";
          this.clearSuccessTimeout();
        }, 10000);
      }
    }
  }

  clearSuccessTimeout() {
    if (this._successTimeout) {
      clearTimeout(this._successTimeout);
      this._successTimeout = null;
    }
  }

  get modalsBackdrop() {
    return window.document.querySelector(`[data-offer-modal="backdrop"]`);
  }

  get successContainer() {
    return window.document.querySelector(`[data-offer-success="container"]`);
  }
}
