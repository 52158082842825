
export class UserEditWorkFlow {

  constructor() {
    this.$success = $('[data-form-success-container]');
    this.$form = $('form#edit-user-form');

    this.initEvents();
  }

  initEvents() {
    this.$form.handleLightAjaxSubmit({
      onResponse: (success, data) => {
        this.clearSuccessContainer();
      }
    });

    $('[data-account-validation-email]').click(event => this.sendAccountValidationEmail(event));
  }

  sendAccountValidationEmail(event) {
    event.preventDefault();
    let $currentTarget = $(event.currentTarget);
    let url = $currentTarget.attr('href');
    let email = $currentTarget.data('account-validation-email');

    $.post(url, {email: email}).done(response => {
      if (response.success) {
        this.displaySuccessContainer(response.message);
      }
    });

  }

  clearSuccessContainer() {
    this.$success.html('');
  }

  displaySuccessContainer(content) {
    this.$form.cleanFormErrors();
    let alert_html = `<div class="alert alert-success" role="alert" data-role="form-success">${content}</div>`;
    this.$success.append(alert_html);
  }


}
