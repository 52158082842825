export class PrepaidAccountEditWorkFlow {
  constructor() {
    this.$form = $('#prepaid-account-edit-form');

    this.initEvents();
  }

  initEvents() {
    this.$form.handleLightAjaxSubmit();
    $('[data-amount-select]').change(event => this.onAmountChange(event));
  }

  onAmountChange(event) {
    let $currentTarget = $(event.currentTarget);
    let selectedValue = $currentTarget.find(':selected').val();
    $('[data-auto-credit-value]').text(selectedValue);

    let $autoCreditCheckbox = $('[data-auto-credit]');
    if (!$autoCreditCheckbox.is(':checked') && (selectedValue && selectedValue !== '')) {
      $autoCreditCheckbox.attr('checked', true);
      $autoCreditCheckbox.prop('checked', true);
      $autoCreditCheckbox.val("true");
    }
  }
}
