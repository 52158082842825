import {loader} from "../utils/Loading";
import {disabler} from "../utils/Disabler";
import {PaymentBackend} from "./PaymentBackend";

export class SecurionPayWorkflow extends PaymentBackend {

  constructor(params) {
    super();
    this.APIKey = params.APIKey;
    this.cardCapture = params.cardCapture === true;

    this.formId = params.formId || "securionpay-form";
    this.$container = $(`#${this.formId}-container`);

    this.theme = params.theme;
    this.paymentFallbackContainer = window.document.querySelector(`[data-payment-fallback]`);

    if (this.$container.length > 0) {
      this._formSubmitEnabled = false;
      this.$form = $(`form#${this.formId}`);
      this.form = this.$form[0];
      this.$formFields = $('[data-show-form]');
      this.$creditCardInfos = $('[data-hide-form]');
      this.$errorContainer = $(`#securionpay-error-container`);

      this.transactionStatus = params.transactionStatus;

      this.load();
      this.selectCreditCard();
      this.initEvents();

      const amountElement = window.document.querySelector(`[data-payment-option-amount-minor-units]`);

      if (amountElement) {
        this.init({
          amount: amountElement.dataset.paymentOptionAmount,
          amountInMinorUnits: amountElement.dataset.paymentOptionAmountMinorUnits,
          currency: amountElement.dataset.paymentOptionCurrency,
        });
      }

      this._formSubmitEnabled = true;
    }
  }

  checkValidity() {
    let isFormValid;
    const tokenize = this.$form.find("input[name=tokenize]").val() === "true";

    if (tokenize) {
      isFormValid = this.form.reportValidity();
      const requiredError = this.form.dataset.requiredError || "Ce champ est obligatoire.";

      if (isFormValid) {
        window.document.querySelectorAll("[data-securionpay]").forEach((input) => {
          if (!input.value) {
            isFormValid = false;
            $(input).setFieldError([requiredError]);
          }
        });
      }
    } else {
      isFormValid = true;
    }

    return isFormValid;
  }

  displayFallback(display) {
    if (this.paymentFallbackContainer) {
      if (display === true) {
        this.paymentFallbackContainer.style.display = this.paymentFallbackContainer.dataset.displayMode || "block";
      } else {
        this.paymentFallbackContainer.style.display = "none";
      }
    }
  }

  initEvents() {

    this.$form.find("button[data-role=submit-button]").click((event) => {
      event.preventDefault();

      $("#recurring-agreement-required-error").hide();
      if ($("#recurring-agreement-required").length && $("#recurring-agreement-required").is(":checked") === false) {
        $("#recurring-agreement-required-error").show();
        return;
      }

      if (!this.transactionStatus.isStarted) {
        this.$form.cleanFormErrors();

        let $currentTarget = $(event.currentTarget);
        let cardHolder = $currentTarget.data('card-holder');
        if (cardHolder && cardHolder !== '') {
          this.$form.find("input[name=card_holder_full_name]").val(cardHolder);
        }

        const isFormValid = this.checkValidity();

        if (isFormValid) {
          loader.show("payment-loader");
          disabler.disable("payment-option-disabler");
          loader.show(`${this.formId}-submit`);
          disabler.disable(`${this.formId}-disabler`);

          this.tokenize();
        }
      }
    });

    this.$form.handleLightAjaxSubmit({
      submitAllowed: (callback) => {
        callback(this._formSubmitEnabled);
      },
      onResponse: (success, data) => {
        this.restoreState();
        this._formSubmitEnabled = !success;

        loader.hide("payment-loader");
        loader.hide(`${this.formId}-submit`);

        if (success) {
          loader.show('main');
          loader.show(`${this.formId}-transaction-finalize`);

          this.transactionStatus.addTransaction(data.transaction);

          if (!this.transactionStatus.isStarted) {
            this.transactionStatus.start().then((response) => {
              window.location.href = data["url"] || response["url"];
            });
          }

        } else {

          this.displayFallback(data.fallback);

          this.onFormResponseError();
        }
      },
      onError: (jqXHR, textStatus, errorThrown) => {
        this.restoreState();
        this.onFormResponseError();
      },
    });

    $("[data-credit-card]").click((event) => {
      this.selectCreditCard();
    });

    $('[data-use-another-credit-card]').click(event => {
      event.preventDefault();
      this.displayCreditCardForm();
    });

    $('[data-use-registered-credit-card]').click(event => {
      event.preventDefault();
      this.displayRegisteredCreditCard();
    });
  }

  onFormResponseError(enableSubmit) {
    loader.hide("payment-loader");
    loader.hide(`${this.formId}-submit`);
    disabler.enable(`${this.formId}-disabler`);
    disabler.enable("payment-option-disabler");

    if (typeof enableSubmit === "boolean") {
      this._formSubmitEnabled = enableSubmit;
    }
  }

  selectCreditCard() {
    const $creditCard = $("[data-credit-card]:checked");
    const id = $creditCard.data("credit-card");

    if (!id || id === "new") {
      this.$formFields.show();
      this.$form.find("input[name=card_holder_full_name]").val("");
      this.$form.find("input[name=tokenize]").val("true");
      this.$form.find("input[name=ccid]").val("");
    } else {
      this.$formFields.hide();
      this.$form.find("input[name=tokenize]").val("false");
      this.$form.find("input[name=card_holder_full_name]").val($creditCard.data("card-holder"));
      this.$form.find("input[name=ccid]").val(id);
    }
  }

  load() {
    if (typeof (Securionpay) !== 'undefined' && this.APIKey) {
      if (this.$form.length > 0) {
        Securionpay.setPublicKey(this.APIKey);
      }
    } else {
      this.displayErrorBe2BillNotLoaded();
    }
  }

  displayErrorBe2BillNotLoaded() {
    this.$errorContainer.show();
    this.$form.hide();
  }

  displayRegisteredCreditCard() {
    const $registeredCreditCard = $("[data-use-registered-credit-card]");
    this.$formFields.hide();
    this.$creditCardInfos.show();
    this.$form.find("input[name=tokenize]").val("false");
    this.$form.find("input[name=ccid]").val($registeredCreditCard.data("credit-card"));
    $('[data-use-another-credit-card]').show();
    $registeredCreditCard.hide();
  }

  displayCreditCardForm() {
    const $registeredCreditCard = $("[data-use-registered-credit-card]");
    this.$formFields.show();
    this.$creditCardInfos.hide();
    this.$form.find("input[name=card_holder_full_name]").val("");
    this.$form.find("input[name=tokenize]").val("true");
    this.$form.find("input[name=ccid]").val("");
    $('[data-use-another-credit-card]').hide();
    $registeredCreditCard.show();
  }

  tokenize() {
    this.$form.find("[data-card-token]")[0].value = "";
    let tokenize = this.$form.find("input[name=tokenize]").val() === "true";

    let formError = this.form.dataset.globalError || "Une erreur s'est produite.";

    if (tokenize && this._formSubmitEnabled) {
      this._formSubmitEnabled = false;

      if (!this.locationSearch) {
        this.locationSearch = window.location.search;
        window.history.replaceState(null, 'Title', document.location.pathname);
      }

      SecurionPay.createCardToken(this.$form, (token) => {

        // Check for errors
        if (token.error) {
          if (token.error.message) {
            formError = token.error.message;
          }
          this.$form.setFormErrors({
            messages: [
              formError
            ]
          });
          this.onFormResponseError(true);
          this.displayFallback(true);

        } else {
          if (this.cardCapture) {
            this.$form.find("[data-card-token]")[0].value = token.id;

            this._formSubmitEnabled = true;
            this.$form.submit();
            this._formSubmitEnabled = false;
          } else {
            const data = this.get3DSecureParams();

            data["card"] = token.id;

            this.$form.find("[data-card-token]")[0].value = token.id;

            // Open frame with 3-D Secure process
            SecurionPay.verifyThreeDSecure(data, (token2) => {

              this.restoreState();
              // Check for errors
              if (token2.error) {

                if (token2.error.message) {
                  formError = token2.error.message;
                }
                this.$form.setFormErrors({
                  messages: [
                    formError
                  ]
                });
                this.onFormResponseError(true);

                this.displayFallback(true);

              } else {
                this.$form.find("[data-card-token]")[0].value = token2.id;

                this.displayFallback(false);

                this._formSubmitEnabled = true;
                this.$form.submit();
                this._formSubmitEnabled = false;
              }
            });
          }
        }
      });
    } else {
      if (this._formSubmitEnabled) {
        this.$form.submit();
        this._formSubmitEnabled = false;
      }
    }

  }

  restoreState() {
    if (this.locationSearch) {
      window.history.replaceState(null, 'Title', document.location.pathname + this.locationSearch);
    }
  }

}

