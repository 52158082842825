import {loader} from "../utils/Loading";
import {jsutils} from "../utils/JSUtils";
import {SelectHandler} from "../utils";
import {disabler} from "../utils/Disabler";
import {PaymentBackend} from "./PaymentBackend";

export class B2BillWorkflow extends PaymentBackend {

  constructor(params) {
    super();

    this.hfields = null;
    this.APIKey = params.APIKey;
    this.APISecret = params.APISecret;

    this.$container = $('#b2bill-form-container');

    this.theme = params.theme;
    this.paymentFallbackContainer = document.querySelector(`[data-payment-fallback]`);

    if (this.theme !== "dark" && this.theme !== "light") {
      this.theme = "light";
    }

    if (this.$container.length > 0) {
      this.formId = params.formId || "b2bill-credit-card-form";
      this.$form = $(`form#${this.formId}`);
      this.form = this.$form[0];
      this.$formFields = $('[data-show-form]');
      this.$creditCardInfos = $('[data-hide-form]');
      this.$errorContainer = $('#be2bill-error-container');
      this.$brandContainer = this.$container.find("#brand-container").closest(".form-group");

      this.url3DSecure = params.url3DSecure;

      this.transactionStatus = params.transactionStatus;
      this.startCheckTransaction = params.startCheckTransaction !== false;

      this.selectedBrand = null;

      const form = window.document.querySelector("form#" + this.formId);
      this.translation = {
        "card-container": form.dataset.labelCardField,
        "cvv-container": form.dataset.labelCvvField,
        "noResults": form.dataset.labelNoResults,
      };

      this.load();

      new SelectHandler({
        $container: this.$form,
        translations: {
          "noResults": this.translation ["noResults"],
        }
      });

      this._formSubmitEnabled = true;

      this.initEvents();
    }
  }

  initEvents() {

    this.$form.find("input[type=number]").on("keydown", (event) => {
      return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key));
    });

    this.$form.find("[data-billing-data=button]").click((event) => {
      event.preventDefault();
      this.$form.find("[data-billing-data=container]").toggle();
    });

    $(document).on("click", "[data-card-brand-value]", (event) => {
      event.preventDefault();
      const $target = $(event.target);
      const brand = $target.data("card-brand-value") || $target.closest("[data-card-brand-value]").data("card-brand-value");
      this.selectBrand(brand);
    });

    this.$form.find("[type=submit]").click(event => {
      event.preventDefault();
      let $currentTarget = $(event.currentTarget);
      let cardHolder = $currentTarget.data('card-holder');
      if (cardHolder && cardHolder !== '') {
        this.$form.find("input[name=card_holder_full_name]").val(cardHolder);
      }

      loader.show("payment-loader");
      disabler.disable("payment-option-disabler");
      loader.show(`${this.formId}-submit`);
      disabler.disable(`${this.formId}-disabler`);

      this.tokenize();
    });

    this.$form.handleLightAjaxSubmit({
      submitAllowed: (callback) => {
        callback(this._formSubmitEnabled);
      },
      onResponse: (success, data) => {
        this._formSubmitEnabled = !success;

        loader.hide("payment-loader");
        loader.hide(`${this.formId}-submit`);

        if (success) {
          loader.show('main');
          loader.show(`${this.formId}-transaction-finalize`);

          if (data['3dsecure']) {
            window.location.href = `${this.url3DSecure}?${data['3dsecure']['url-params']}`;
          } else {
            this.transactionStatus.addTransaction(data.transaction);

            if (this.startCheckTransaction && !this.transactionStatus.isStarted) {
              this.transactionStatus.start().then(response => {
                window.location.href = data['url'] || response["url"];
              });
            }
          }
        } else {

          if (this.paymentFallbackContainer) {
            if (data.fallback) {
              this.paymentFallbackContainer.style.display = this.paymentFallbackContainer.dataset.displayMode || "block";
            } else {
              this.paymentFallbackContainer.style.display = "none";
            }
          }

          this.onFormResponseError(true);
        }
      },
      onError: (jqXHR, textStatus, errorThrown) => {
        this.onFormResponseError(true);
      },
    });

    $("[data-credit-card]").click((event) => {
      this.selectCreditCard();
    });

    $('[data-use-another-credit-card]').click(event => {
      event.preventDefault();
      this.displayCreditCardForm();
    });

    $('[data-use-registered-credit-card]').click(event => {
      event.preventDefault();
      this.displayRegisteredCreditCard();
    });
  }

  onFormResponseError(enableSubmit) {
    loader.hide("payment-loader");
    loader.hide(`${this.formId}-submit`);
    disabler.enable(`${this.formId}-disabler`);
    disabler.enable("payment-option-disabler");

    if (typeof enableSubmit === "boolean") {
      this._formSubmitEnabled = enableSubmit;
    }
  }

  selectCreditCard() {
    const $creditCard = $("[data-credit-card]:checked");
    const id = $creditCard.data("credit-card");

    if (id === "new") {
      this.$formFields.show();
      this.$form.find("input[name=card_holder_full_name]").val("");
      this.$form.find("input[name=tokenize]").val("true");
      this.$form.find("input[name=ccid]").val("");
    } else {
      this.$formFields.hide();
      this.$form.find("input[name=tokenize]").val("false");
      this.$form.find("input[name=card_holder_full_name]").val($creditCard.data("card-holder"));
      this.$form.find("input[name=ccid]").val(id);
    }
  }

  load() {
    if (typeof be2bill !== 'undefined') {
      this.initClientFields();
      this.initHostedFields();
      if (this.hfields) {
        this.hfields.load();
      }
    } else {
      this.displayErrorBe2BillNotLoaded();
    }
  }

  displayErrorBe2BillNotLoaded() {
    this.$errorContainer.show();
    this.$form.hide();
  }

  displayRegisteredCreditCard() {
    const $registeredCreditCard = $("[data-use-registered-credit-card]");
    this.$formFields.hide();
    this.$creditCardInfos.show();
    this.$form.find("input[name=tokenize]").val("false");
    this.$form.find("input[name=ccid]").val($registeredCreditCard.data("credit-card"));
    $('[data-use-another-credit-card]').show();
    $registeredCreditCard.hide();
  }

  displayCreditCardForm() {
    const $registeredCreditCard = $("[data-use-registered-credit-card]");
    this.$formFields.show();
    this.$creditCardInfos.hide();
    this.$form.find("input[name=card_holder_full_name]").val("");
    this.$form.find("input[name=tokenize]").val("true");
    this.$form.find("input[name=ccid]").val("");
    $('[data-use-another-credit-card]').hide();
    $registeredCreditCard.show();
  }

  initHostedFields() {

    let styles = {
      "light": {
        "input": {
          "line-height": "20px",
          "font-size": "1em",
          "background-color": "transparent",
          "color": "#696969"
        },
        "::placeholder": {
          "font-size": "1em",
          "color": "#a9a9a9"
        }
      },
      "dark": {
        "input": {
          "line-height": "20px",
          "font-size": "15px",
          "background-color": "transparent",
          "color": "#ffffff"
        },
        "::placeholder": {
          "font-size": "15px",
          "color": "#757575"
        }
      }
    };

    let style = styles[this.theme];

    if (this.$form.length > 0) {
      this.hfields = be2bill.hostedFields({
        key: {
          id: this.APIKey,
          value: this.APISecret
        },
        fields: {
          'card': {
            id: 'card-container',
            style: style,
            placeholder: this.translation['card-container'],
            onInput: (event) => {

              const cardType = event.cardType;
              let brands = event.brands;

              if (cardType && brands) {

                this.selectBrand(cardType);

                if (brands && brands.length > 0) {
                  this.updateBrandsSelector(brands);
                } else {
                  brands = [];
                  if (cardType && cardType !== "unknown") {
                    brands.push({
                      brand: cardType,
                    })
                  }
                  this.updateBrandsSelector(brands);
                }

                if (cardType !== "unknown" && brands.length > 0) {
                  jsutils.removeClass(this.$brandContainer[0], "hidden-xs");
                } else {
                  jsutils.addClass(this.$brandContainer[0], "hidden-xs");
                }
              } else {
                this.updateBrandsSelector([{brand: "visa"}, {brand: "cb"}, {brand: "mastercard"}]);
                this.selectBrand("visa");
                jsutils.removeClass(this.$brandContainer[0], "hidden-xs");
              }
            },
          },
          'expiry': {
            id: 'expiry-container',
            placeholder: 'MM/YY',
            style: style
          },
          'cryptogram': {
            id: 'cvv-container',
            style: style,
            placeholder: this.translation['cvv-container'],
          }
        },
        location: "fr",
      });
    }
  }

  tokenize() {
    this.$form.find("[data-card-token]")[0].value = "";
    let tokenize = this.$form.find("input[name=tokenize]")[0].value === "true";

    if (this.hfields && tokenize) {
      this.hfields.createToken(result => {
        if (result.execCode === '0000') {
          this.$form.find("[data-card-token]")[0].value = result.hfToken;
        }
        if (this.paymentFallbackContainer) {
          this.paymentFallbackContainer.style.display = "none";
        }
        this.$form.submit();
        this._formSubmitEnabled = false;
      });
    } else {
      if (this.paymentFallbackContainer) {
        this.paymentFallbackContainer.style.display = "none";
      }
      this.$form.submit();
      this._formSubmitEnabled = false;
    }
  }

  updateBrandsSelector(brandsList) {
    const $cardBrands = $("#card-brands-list");
    $cardBrands.empty();

    $.each(brandsList, (index, brand) => {
      const $li = $(`<li class="card-brand ${brand.brand}"><a href="#" data-card-brand-value="${brand.brand}"><span>${brand.brand}</span></a></li>`);
      if (brand.brand === this.selectedBrand) {
        $li.addClass("selected");
      }
      $cardBrands.append($li);
    });
  }

  selectBrand(brand) {
    const $selectedBrandText = $("[data-selected-brand-text]");
    $selectedBrandText.removeClass(this.selectedBrand);

    this.selectedBrand = brand;
    this.$form.find("[data-card-brand]")[0].value = brand;
    $selectedBrandText.data("selected-brand-text", brand);
    $selectedBrandText.html(`<span>${brand}</span>`);
    $selectedBrandText.addClass(brand);
  }
}

