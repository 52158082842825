import {CountDown, RemoteChecker} from "../utils";
import {PaymentBackend} from "./PaymentBackend";

export class SmartCall extends PaymentBackend {

  constructor(params) {
    super();

    this.$container = $("[data-payment-option-container=smart-call]");

    if (this.$container.length > 0) {
      this.transactionStatus = params.transactionStatus;

      this.url = $("[data-new-phone-number]").attr("href");
      this.availablePhoneChecker = new RemoteChecker(this.url, 5000);

      this.countDown = new CountDown(this.$container.find("#validity-timer"), 90);
      this.$phoneImageContainer = $("[data-phone-container=img]");
      this.$phoneTextContainer = $("[data-phone-container=text]");

      this.$activeContainer = $("#phone-active-container");
      this.$inactiveContainer = $("#phone-inactive-container");
      this.$waitingContainer = $("#phone-waiting-container");

      this.initEvents();
    }
  }

  initEvents() {
    $("[data-new-phone-number]").click(event => this.onNewPhoneNumberClick(event));
    let $parent = this.$container.closest("[data-container=payment-option]");

    if ($parent.length > 0) {
      $parent.bind("show", _ => this.onShow());
      $parent.bind("hide", _ => this.onHide());
    } else {
      this.onShow();
    }
  }

  getPhoneData() {
    $.get(this.url).done(response => {
      this.transactionStatus.addTransaction(response.transaction);

      if (!this.transactionStatus.isStarted) {
        this.transactionStatus.start().then(response => {
          window.location.href = response["url"];
        });
      }

      this.updateData(response);
      this.startCountDown();

    }).fail(response => {
      this.countDown.stop();
      this.$activeContainer.hide();
      this.$inactiveContainer.hide();
      this.$waitingContainer.show();
      this.startAvailablePhoneChecker();
    });
  }

  updateData(data) {
    this.$phoneImageContainer.find("img").attr("src", data.imageUrl);
    this.$phoneTextContainer.html(data.phone);
    this.$phoneImageContainer.find("a").attr("href", `tel:${data.phone}`);

    $("[data-phone-tel]").attr("href", `tel:${data.phone}`);

    if (!data.imageUrl) {
      this.$phoneImageContainer.hide();
      this.$phoneTextContainer.show();
    } else {
      this.$phoneImageContainer.show();
      this.$phoneTextContainer.hide();
    }

    this.$activeContainer.show();
    this.$inactiveContainer.hide();
    this.$waitingContainer.hide();
  }

  onShow() {
    this.getPhoneData();
    this.$activeContainer.show();
    this.$inactiveContainer.hide();
    this.$waitingContainer.hide();
  }

  onHide() {
    this.countDown.stop();
    this.countDown.reset();
    this.availablePhoneChecker.stop();
  }

  startCountDown() {
    this.countDown.start(_ => {
      this.$activeContainer.hide();
      this.$inactiveContainer.show();
      this.$waitingContainer.hide();
    });
  }

  onNewPhoneNumberClick(event) {
    event.preventDefault();
    this.countDown.reset();
    this.getPhoneData();
  }

  startAvailablePhoneChecker() {
    if (!this.availablePhoneChecker.isStarted) {
      this.availablePhoneChecker.start().then(response => {
        this.onShow();
      });
    }
  }

}

