export class PasswordWorkflow {

  constructor() {
    this.$form = $('form#user-password-form');

    this.initEvents();
  }

  initEvents() {
    this.$form.handleLightAjaxSubmit();
  }
}
