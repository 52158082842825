require('core-js/modules/es6.symbol');
require('core-js/modules/es6.array.iterator');
require('core-js/modules/es6.promise');

import Raven from 'raven-js';

Raven
    .config('https://1cfda061e666cae0afa7eede64432613@sentry.tawenda-tech.org/4', {
            release: "__VERSION__",
            whitelistUrls: [
                /straceo\.com/,
            ]
        })
    .install();

const utils = require('./utils');
const members = require('./members');
const affiliates = require('./affiliates');
const connection = require('./connection');
const support = require('./support');
const payments = require('./payments');
const subscriptions = require('./subscriptions');
const studios = require('./studios');

export {payments, utils, members, connection, support, subscriptions, affiliates, studios};
