import {jsutils} from "../utils/JSUtils";
import {loader} from "../utils/Loading";

export class PaymentOption {

  constructor(params) {

    this.$iframeContainer = $('[data-container=foreign-payment-option]');
    this.$iframe = $('[data-container=foreign-payment-option] iframe');

    this.id = params.id;
    this.url = params.url;
    this.hasTemplate = params.hasTemplate;
    this.template = params.template;
    this.checkTransactionStatus = params.checkTransactionStatus;
    this.gaLocation = params.gaLocation;
    this.cssProperties = params.cssProperties;
    this.reload = params.reload;
    this.amount = params.amount;
    this.currency = params.currency || "â¬";
    this.amountMinorUnits = params.amountMinorUnits;
    this.label = params.label;
    this.description = params.description;
    this.isSubscription = params.isSubscription;

    this.transactionStatus = params.transactionStatus;

    this.ga = params.ga;
    this.initEvents();
  }

  initEvents() {
    let firstActive = document.body;

    this.$iframe.load((event) => {
      loader.hide('payment-option');
      firstActive.focus();
    });
  }

  show() {
    jsutils.addClass(this.$element[0], 'active');

    if (this.ga) {
      this.ga.sendVirtualPageView(this.gaLocation);
    }

    if (this.reload) {
      loader.show('main');
      document.location.href = this.url;
    } else {
      if (this.hasTemplate) {
        this._displayContainer();
      } else {
        this._loadIframe();
      }
    }
  }

  hide() {
    jsutils.removeClass(this.$element[0], 'active');

    let $container = $(`[data-container=payment-option][data-payment-option-template=${this.template}]`);
    $container.fadeOut(_ => {
      $container.trigger('hide');
    });
    this.$iframeContainer.hide();
  }

  _displayContainer() {
    let $container = $(`[data-container=payment-option][data-payment-option-template=${this.template}]`);
    let $form = $container.find('form[data-payment-option-form]');

    if ($form.length === 1) {
      $form[0].action = this.url;
    }

    this._updatePaymentOptionData($container);

    $container.fadeIn(_ => {
      $container.trigger('show');
    });
  }

  _updatePaymentOptionData($container) {
    $container.find('[data-payment-option-url]').data('payment-option-url', this.url);
    $container.find('[data-payment-option-amount]').html(this.amount + " " + this.currency);
    $container.find('[data-payment-option-name]').html(this.label);


    if (this.isSubscription === true) {
      $container.find('[data-subscription-info]').show();
      $container.find('[data-payment-option-description]').html("");
      $container.find('[data-subscription-renewal]').html(this.description);
    } else {
      $container.find('[data-subscription-info]').hide();
      $container.find('[data-payment-option-description]').html(this.description);
      $container.find('[data-payment-option-amount-minor-units]').html(this.amountMinorUnits);
    }
  }

  _loadIframe() {
    // loader.show('payment-option');
    // $('[data-container=payment-option]').hide();
    this.$iframeContainer.show();
    $.get(this.url).done(response => {
      if (response.success) {
        if (this.checkTransactionStatus) {
          this.transactionStatus.addTransaction(response.transaction);
        }
        this.$iframe[0].setAttribute('src', response.url);
        this.$iframe.css(this.cssProperties);
        this.$iframeContainer.show();
      }
    });

  }

  get $element() {
    return $(`#${this.id}`);
  }
}
