export class GenerateAffiliationLinkWorkflow {
  constructor() {
    this.$form = $("form#generate-url-form");
    this.$generatedUrlContainers = $("[data-generated-url]");

    $('[data-select2] select').select2();

    this.initEvents();
  }

  initEvents() {
    this.$form.find("select").change(() => {
      this.onFormFieldChange();
    });

    this.$form.find("input[type=text]").keydown(() => {
      this.onFormFieldChange();
    });

    this.$form.handleLightAjaxSubmit({
      onResponse: (success, data) => {
        if (success && data['affiliation-url']) {
          this.$generatedUrlContainers.show();
          $("[data-generated-url=content]").html(data['affiliation-url']);
        }
      },
    });
  }

  onFormFieldChange() {
    this.$generatedUrlContainers.hide();
  }
}
