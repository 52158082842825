import {loader} from "../utils/Loading";
import {Ga, ReCaptchaManager, SimpleAjaxFormWorkflow} from "../utils";

export class RegisterWorkFlow extends SimpleAjaxFormWorkflow {
  constructor(params = {}) {
    super("create-user-form");

    if (params.ga.location) {
      this.ga = new Ga({getRemoteTracker: params.ga.getRemoteTracker});
      this.gaLocation = params.ga.location;
      this.ga.sendVirtualPageView(this.gaLocation);
    }

    this.captchaManager = new ReCaptchaManager({
      inputName: "captcha",
    });
  }

  onFormSuccess(data) {
    loader.show('main');
  }

  onFormError(data, response) {
    this.captchaManager.init();
  }

  onAjaxError() {
    this.captchaManager.init();
  }
}
