import {Ga} from "../../utils/index";

export class PaymentFinalizedWorkFlow {

  constructor(params) {
    this.collectTransactions(params.transactions);

    setTimeout(_ => {
      document.location.href = params.url;
    }, 1000);
  }

  collectTransactions(transactions) {
    if (transactions) {
      const ga = new Ga({getRemoteTracker: false});
      $.each(transactions, (i, data) => {
        ga.sendEcommerceData(data.transaction, data.item);
      });
    }
  }
}
