import {Ga, ReCaptchaManager} from "../utils";

export class ContactWorkflow {

  constructor(params) {
    this.$form = $(`#${params.formId}`);
    this.$success = $('[data-form-success-container]');
    this.initEvents();

    let ga = new Ga({getRemoteTracker: false});
    ga.sendVirtualPageView("support");

    this.captchaManager = new ReCaptchaManager({
      inputName: "captcha",
    });
  }

  initEvents() {
    this.$form.handleLightAjaxSubmit({
      onResponse: (success, data) => {
        if (success) {
          this.displaySuccessContainer(data.message);
        } else {
          this.captchaManager.init();
        }
        this.captchaManager.reset();
      }
    });
  }

  displaySuccessContainer(content) {
    this.$form.cleanFormErrors();
    let alert_html = `<div class="alert alert-success" role="alert" data-role="form-success">${content}</div>`;
    this.$success.append(alert_html);
    this.$form.trigger("reset");
  }
}
