import {loader} from "../utils/Loading";
import {disabler} from "../utils/Disabler";
import {cookies} from "../utils/Cookies";
export class PurchaseV2 {

  constructor(params) {
    this.mps = params.mps;
    this.initEvents();
    this.onOfferSelected($("[data-offer-option]:checked").val());

  }

  sendToMixpanel(event, properties) {
    if (this.mps)  {
      this.mps.send(event, properties);
    }
  }

  initEvents() {

    $("[data-offer-option]").click(event => {
      let value = $(event.currentTarget).val();
      this.onOfferSelected(value);
    });

    $("[data-cross-sell=input]").change((event) => {
      event.currentTarget.dataset.crossSellChecked = event.currentTarget.checked ? "checked" : "";
      const offerId = event.currentTarget.closest("[data-cross-sell-offer]").dataset.crossSellOffer;
      const sitename = event.currentTarget.dataset.mpCrossSellSitename;
      const crossSellDatas = {
        'is_checked': event.currentTarget.checked,
        'offer_id': offerId,
        'sitename': sitename,
      };

      this.sendToMixpanel('Change Cross Sell Click', crossSellDatas);
      this.updateCrossSells(offerId);
    });

    window.document.querySelectorAll(`[data-option-for-offer] input[type="radio"]`).forEach((input) => {
      input.addEventListener("change", (event) => {
        const selectedOffer = input.closest(`[data-option-for-offer]`).dataset.optionForOffer;
        this.toggleCrossSells(selectedOffer);
      });
    });
    $("button[data-goto-step=3]").on("click", (event) => {
      event.preventDefault();

      const checkedOffer = $("[data-offer-option]:checked");
      const crossSellsCheckedList = this.getCrossSellsChecked;       
      const mp_datas= {
        'offer_type_selected': checkedOffer.attr("mp-offer-type"),
        'offer_id_selected': checkedOffer.attr("mp-offer-id"),
        'payment_method_id_selected': this.$selectedPaymentOption.attr("mp-payment-method-id"),
        'payment_method_type_selected': this.$selectedPaymentOption.attr("mp-payment-method-type"),
        'cross_sell_selected': JSON.stringify(crossSellsCheckedList),
      };

      this.sendToMixpanel('Proceed To Checkout Click', mp_datas);

      let $currentTarget = $(event.currentTarget);
      if ($("#explicit-terms-validation").length && $("#explicit-terms-validation").is(":checked") === false) {
        loader.hide($currentTarget.data('loading'));
        $("#explicit-terms-validation-error").show();
      } else {
        $("#explicit-terms-validation-error").hide();
        const url = this.$selectedPaymentOption.val();
        if (url) {
          loader.show("main");
          window.location.href = url;
          disabler.disable($currentTarget.data("manualDisable"));
        }

      }
    });
  }

 get getCrossSellsChecked(){
    const crossSellsCheckedList = []; 
    document.querySelectorAll('[data-cross-sell-offer]:not([style*="display: none;"]) input[data-cross-sell]:checked').forEach(element => {
      crossSellsCheckedList.push({sitename: element.dataset.mpCrossSellSitename, value: element.value});
    });
    return crossSellsCheckedList;
  }

  updateCrossSells(offerId) {
    this.crossSells = new Set();

    $(`[data-cross-sell-offer="${offerId}"] [data-cross-sell="input"]:checked`).each((index, el) => {
      this.crossSells.add(el.value);
      // $(`[data-cross-sell-description="${offerId}_${el.value}"]`).show();
    });

    const csidValue = Array.from(this.crossSells).join(",");

    $(`[data-option-for-offer="${offerId}"] [data-payment-option]`).each((index, el) => {
      el.value = this.updateUrl(el.value, "csid", csidValue);
    });
  }

  onOfferSelected(selectedOffer) {

    if (selectedOffer) {

      $(`[data-cross-sell-offer]`).hide();
      // $(`[data-cross-sell-description]`).hide();
      $('[data-option-for-offer]').hide();

      window.document.querySelectorAll(`[data-option-for-offer] input[type=radio]:checked`).forEach((input) => {
        input.checked = false;
      });

      this.toggleCrossSells(selectedOffer);

      $(`[data-option-for-offer="${selectedOffer}"]`).show();

    }
  }

  get $selectedPaymentOption() {
    return $("input[type=radio][name=payment_option]:checked");
  }

  updateUrl(url, key, value) {
    let res = url;

    if (url) {
      let urlObj = null;

      try {
        urlObj = new URL(url);
      } catch (error) {
        urlObj = new URL(`${window.location.origin}${url}`);
      }

      const searchParams = urlObj.searchParams;

      if (searchParams) {
        const isNullValue = value === null || value === "";

        if (isNullValue) {
          if (searchParams.has(key)) {
            searchParams.delete(key);
          }
        } else {
          searchParams.set(key, value);
        }

        res = `${urlObj.origin}${urlObj.pathname}?${searchParams.toString()}`;
      } else {
        const currentUrl = window.location.href;
        let separator = "&";

        if (currentUrl.indexOf("?") < 0) {
          separator = "?";
        }

        res = `${currentUrl}${separator}${key}=${value}`;
      }
    }

    return res;
  }

  toggleCrossSells(selectedOffer) {

    const selectFirstPaymentOption = window.document.querySelectorAll(`[data-option-for-offer="${selectedOffer}"] input[type=radio]:checked`).length === 0;

    let input = window.document.querySelector(`[data-option-for-offer="${selectedOffer}"] input[type=radio]:checked`);

    if (selectFirstPaymentOption) {
      input = window.document.querySelectorAll(`[data-option-for-offer="${selectedOffer}"] input[type=radio]`)[0];

      if (input) {
        input.checked = true;
      }

    }

    const parent = input ? input.closest(`[data-backend-id]`) : undefined;

    $(`[data-cross-sell-description]`).hide();

    if (parent) {
      // const crossSellsEnabled = parent.dataset.paymentOptionCrossSellsEnabled === "true";

      const backendId = parent.dataset.backendId;

      window.document.querySelectorAll(`[data-cross-sell-offer="${selectedOffer}"]`).forEach((el) => {
        const csInput = el.querySelector(`input[type="checkbox"]`);

        const availableForBackends = el.dataset.crossSellForBackends.split(",");

        if (availableForBackends.indexOf(backendId) >= 0) {
          el.style.display = el.dataset.displayMode || "block";

          if (csInput) {
            csInput.checked = csInput.dataset.crossSellChecked === "checked";
          }

          $(`[data-cross-sell-description="${csInput.id}"]`).show();

        } else {
          el.style.display = "none";

          if (csInput) {
            csInput.checked = false;
          }
        }
      });
    }
    this.updateCrossSells(selectedOffer);
  }
}



