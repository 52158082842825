import {loader} from "../utils/Loading";
import {disabler} from "../utils/Disabler";

export class Audiotel {

  constructor(formId) {
    this.formId = formId || "code-validation-form";
    this.$form = $(`form#${this.formId}`);

    if (this.$form.length > 0) {
      this.$form.handleLightAjaxSubmit({
        beforeSubmit: ($form) => {
          loader.show(`${this.formId}-submit`);
          disabler.disable(`${this.formId}-disabler`);
        },
        onResponse: (success, data) => {
          loader.hide(`${this.formId}-submit`);

          if (success) {
            loader.show("main");
            loader.show(`${this.formId}-transaction-finalize`);
          } else {
            loader.hide(`${this.formId}-submit`);
            disabler.enable(`${this.formId}-disabler`);
          }
        },
        onError: (jqXHR, textStatus, errorThrown) => {
          loader.hide(`${this.formId}-submit`);
          disabler.enable(`${this.formId}-disabler`);
        },
      });

      const selectorPrice = window.document.querySelector(".selector__price");

      if (selectorPrice) {
        selectorPrice.dataset.initialHtml = selectorPrice.innerHTML;
      }

      window.document.querySelectorAll("[data-audiotel-info]").forEach((element) => {
        element.addEventListener("click", (event) => {
          const country = element.dataset.audiotelInfo;
          const countryContainers = window.document.querySelectorAll(`[data-audiotel-container-country]`);

          countryContainers.forEach((countryContainer) => {

            if (countryContainer.dataset.audiotelContainerCountry === country) {
              countryContainer.style.display = countryContainer.dataset.displayMode || "block";

              if (selectorPrice) {
                const amount = element.dataset.audiotelAmountToDisplay;

                if (amount) {
                  selectorPrice.innerHTML = amount;
                } else {
                  selectorPrice.innerHTML = selectorPrice.dataset.initialHtml;
                }
              }

            } else {
              countryContainer.style.display = "none";
            }
          });
        });
      });
    }
  }
}

