import {loader} from "../utils/Loading";
import {disabler} from "../utils/Disabler";
import {PaymentBackend} from "./PaymentBackend";

export class DimocoWorkflow extends PaymentBackend {

  constructor(params) {
    super();
    this.APIKey = params.APIKey;
    this.cardCapture = params.cardCapture === true;

    this.formId = params.formId || "dimoco-form";
    this.$container = $(`#${this.formId}-container`);

    this.theme = params.theme;
    this.paymentFallbackContainer = window.document.querySelector(`[data-payment-fallback]`);

    this.payment = null;

    if (this.$container.length > 0) {
      this._formSubmitEnabled = false;
      this.$form = $(`form#${this.formId}`);
      this.form = this.$form[0];
      this.$formFields = $('[data-show-form]');
      this.$creditCardInfos = $('[data-hide-form]');
      this.$errorContainer = $(`[data-error-container="${this.formId}"]`);

      this.transactionStatus = params.transactionStatus;

      this.load();
      this.selectCreditCard();
      this.initEvents();

      const amountElement = window.document.querySelector(`[data-payment-option-amount-minor-units]`);

      if (amountElement) {
        this.init({
          amount: amountElement.dataset.paymentOptionAmount,
          amountInMinorUnits: amountElement.dataset.paymentOptionAmountMinorUnits,
          currency: amountElement.dataset.paymentOptionCurrency,
        });
      }

      this._formSubmitEnabled = true;
    }
  }

  checkValidity() {
    let isFormValid;
    const tokenize = this.$form.find("input[name=tokenize]").val() === "true";

    if (tokenize) {
      isFormValid = this.form.reportValidity();
      const requiredError = this.form.dataset.requiredError || "Ce champ est obligatoire.";

      if (isFormValid) {
        window.document.querySelectorAll("input[data-dimoco]").forEach((input) => {
          if (!input.value) {
            isFormValid = false;
            $(input).setFieldError([requiredError]);
          }
        });
      } else {
        window.document.querySelectorAll("input[data-dimoco]").forEach((input) => {
          if (input.value) {
            if (1 < input.maxLength < input.value.length) {
              const maxLengthError = input.dataset.maxLengthError || "Trop long.";
              $(input).setFieldError([maxLengthError]);
            } else if (input.minLength > 1 && input.value.length < input.minLength) {
              const minLengthError = input.dataset.minLengthError || "Trop court.";
              $(input).setFieldError([minLengthError]);
            }
          }
        });
      }
    } else {
      isFormValid = true;
    }

    return isFormValid;
  }

  displayFallback(display) {
    if (this.paymentFallbackContainer) {
      if (display === true) {
        this.paymentFallbackContainer.style.display = this.paymentFallbackContainer.dataset.displayMode || "block";
      } else {
        this.paymentFallbackContainer.style.display = "none";
      }
    }
  }

  initEvents() {

    this.$form.find("button[data-role=submit-button]").click((event) => {
      event.preventDefault();

      if (!this.transactionStatus.isStarted) {
        this.$form.cleanFormErrors();

        let $currentTarget = $(event.currentTarget);
        let cardHolder = $currentTarget.data('card-holder');
        if (cardHolder && cardHolder !== '') {
          this.$form.find("input[name=card_holder_full_name]").val(cardHolder);
        }

        const isFormValid = this.checkValidity();

        if (isFormValid) {
          loader.show("payment-loader");
          disabler.disable("payment-option-disabler");
          loader.show(`${this.formId}-submit`);
          disabler.disable(`${this.formId}-disabler`);

          this.tokenize();
        }
      }
    });

    this.$form.handleLightAjaxSubmit({
      submitAllowed: (callback) => {
        callback(this._formSubmitEnabled);
      },
      onResponse: (success, data) => {
        this._formSubmitEnabled = !success;

        loader.hide("payment-loader");
        loader.hide(`${this.formId}-submit`);

        if (success) {
          loader.show('main');
          loader.show(`${this.formId}-transaction-finalize`);

          this.transactionStatus.addTransaction(data.transaction);

          if (!this.transactionStatus.isStarted) {
            this.transactionStatus.start().then((response) => {
              const url = data["url"] || response["url"];

              if (url) {
                window.location.href = url;
              }
            });
          }

        } else {

          this.displayFallback(data.fallback);

          this.onFormResponseError();
        }
      },
      onError: (jqXHR, textStatus, errorThrown) => {
        this.displayFallback(true);
        this.onFormResponseError();
      },
    });

    $("[data-credit-card]").click((event) => {
      this.selectCreditCard();
    });

    $('[data-use-another-credit-card]').click(event => {
      event.preventDefault();
      this.displayCreditCardForm();
    });

    $('[data-use-registered-credit-card]').click(event => {
      event.preventDefault();
      this.displayRegisteredCreditCard();
    });
  }

  onFormResponseError(enableSubmit) {
    loader.hide("payment-loader");
    loader.hide(`${this.formId}-submit`);
    disabler.enable(`${this.formId}-disabler`);
    disabler.enable("payment-option-disabler");

    if (typeof enableSubmit === "boolean") {
      this._formSubmitEnabled = enableSubmit;
    }
  }

  selectCreditCard() {
    let $creditCard = $("[data-credit-card]:checked");

    if ($creditCard.length === 0) {
      // Straceo v1
      $creditCard = $("input[type=button][data-credit-card]");
    }

    const id = $creditCard.data("credit-card");

    if (!id || id === "new") {
      this.$formFields.show();
      this.$form.find("input[name=card_holder_full_name]").val("");
      this.$form.find("input[name=tokenize]").val("true");
      this.$form.find("input[name=ccid]").val("");
    } else {
      this.$formFields.hide();
      this.$form.find("input[name=tokenize]").val("false");
      this.$form.find("input[name=card_holder_full_name]").val($creditCard.data("card-holder"));
      this.$form.find("input[name=ccid]").val(id);
    }
  }

  load() {
    if (typeof (PaymentJs) !== 'undefined' && this.APIKey) {
      if (this.$form.length > 0) {

        this.initClientFields();

        this.payment = new PaymentJs();

        this.payment.init(this.APIKey, "id_card_number", "id_cvc", (payment) => {

          let style = getComputedStyle($('#id_card_number')[0]);
          let hexaColor;

          try {
            hexaColor = this.RGBToHex(style.color);
          } catch (e) {

          }

          let inputsStyle = {
            "border": "none",
            "height": "100%",
            "width": "100%",
            "background-color": "transparent",
            "font-size": style.fontSize,
            "outline": "none",
          };

          if (hexaColor) {
            inputsStyle["color"] = hexaColor;
          }

          payment.setNumberStyle(inputsStyle);
          payment.setNumberPlaceholder($('#id_card_number').data("placeholder"));

          payment.setCvvStyle(inputsStyle);
          payment.setCvvPlaceholder($('#id_cvc').data("placeholder"));
        });

      }
    } else {
      this.displayErrorApiNotLoaded();
    }
  }

  displayErrorApiNotLoaded() {
    this.$errorContainer.show();
    this.$form.hide();
  }

  displayRegisteredCreditCard() {
    const $registeredCreditCard = $("[data-use-registered-credit-card]");
    this.$formFields.hide();
    this.$creditCardInfos.show();
    this.$form.find("input[name=tokenize]").val("false");
    this.$form.find("input[name=ccid]").val($registeredCreditCard.data("credit-card"));
    $('[data-use-another-credit-card]').show();
    $registeredCreditCard.hide();
  }

  displayCreditCardForm() {
    const $registeredCreditCard = $("[data-use-registered-credit-card]");
    this.$formFields.show();
    this.$creditCardInfos.hide();
    this.$form.find("input[name=card_holder_full_name]").val("");
    this.$form.find("input[name=tokenize]").val("true");
    this.$form.find("input[name=ccid]").val("");
    $('[data-use-another-credit-card]').hide();
    $registeredCreditCard.show();
  }

  refreshCVV() {
    // TODO for registered cards case
    this.payment.refreshCvv(
      () => { //success callback function
        this.form.submit();
      },
      (errors) => {
        this.displayDimocoErrors(errors);
        this.onFormResponseError(true);
      }
    );
  }

  tokenize() {

    const tokenize = this.$form.find("input[name=tokenize]").val() === "true";

    if (tokenize && this._formSubmitEnabled) {
      this._formSubmitEnabled = false;

      var data = {
        card_holder: $('#id_card_holder_full_name').val(),
        month: $('#id_exp_month').val(),
        year: $('#id_exp_year').val(),
        email: $('#id_email').val()
      };

      this.payment.tokenize(
        data,
        (token, cardData) => { //success callback function
          this.$form.find("[data-card-token]")[0].value = token;

          this._formSubmitEnabled = true;
          this.$form.submit();
          this._formSubmitEnabled = false;

        },
        (errors) => { //error callback function
          this.displayDimocoErrors(errors);
          this.displayFallback(true);
          this.onFormResponseError(true);
        }
      );
    } else {
      if (this._formSubmitEnabled) {
        this.$form.submit();
        this._formSubmitEnabled = false;
      }
    }
  }

  displayDimocoErrors(errors) {
    const errorMessages = [];

    for (let i = 0; i < errors.length; i++) {
      const error = errors[i];
      errorMessages.push(error["message"]);
    }

    this.$form.setFormErrors({
      messages: errorMessages
    });
  }

  RGBToHex(rgb) {
    // Choose correct separator
    let sep = rgb.indexOf(",") > -1 ? "," : " ";
    // Turn "rgb(r,g,b)" into [r,g,b]
    rgb = rgb.substr(4).split(")")[0].split(sep);

    let r = (+rgb[0]).toString(16),
      g = (+rgb[1]).toString(16),
      b = (+rgb[2]).toString(16);

    if (r.length == 1) {
      r = "0" + r;
    }
    if (g.length == 1) {
      g = "0" + g;
    }
    if (b.length == 1) {
      b = "0" + b;
    }
    return "#" + r + g + b;
  }

}

