import {AccountExists} from "../utils/index";
import EventClass from "../utils/EventClass";

export class Register extends EventClass {

  constructor(params) {
    super();

    this.$email = $('[data-username-field]');
    this.$form = this.$email.closest("form");

    this.translation = params.translation;

    this._registerAndRedirectUrl = params.registerAndRedirectUrl;
    this._accountExists = false;
    this.accountChecker = new AccountExists(params.accountCheckUrl, this.$email);

    this.initEvents();
    this.waitForEmail();
  }

  initEvents() {

    this.accountChecker.on('email-change', _ => {
      this._accountExists = false;
      this.emit('email-change');
      this.$form.cleanFormErrors();
      if (!this.emailIsValid) {
        this.$email.setFieldError([this.translation.invalidEmail]);
      }
    });
  }

  waitForEmail() {
    this.accountChecker.start().then(response => {
      this.emit('account-exists');
      this._accountExists = true;
      this.$email.cleanFormErrors();
      this.$email.setFieldError([this.translation.accountExists]);
      this.waitForEmail();
    });
  }

  get emailIsValid() {
    return this.accountChecker.emailIsValid;
  }

  register(url) {
    return new Promise((resolve, reject) => {
      if (!this.emailIsValid) {
        this.$email.setFieldError([this.translation.invalidEmail]);
        return reject();
      } else if (this._accountExists) {
        this.$email.setFieldError([this.translation.accountExists]);
        return reject();
      }
      $.post(this._registerAndRedirectUrl, {email: this.$email.val(), url: url})
        .done(response => resolve(response))
        .fail(_ => resolve({success: false}));
    });

  }
}
