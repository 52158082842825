import {mathsutils} from "../utils/MathsUtils";
import {Ga} from "../utils";
import {PaymentBackendsManager} from "./PaymentBackendsManager";

export class PrepaidAccountWorkFlow {

  constructor(params) {

    this.mps = params.mps;

    this.$autoCredit = $('[data-auto-credit]');

    this.paymentBackendsManager = new PaymentBackendsManager(params.transactionStatus);

    this.transactionStatus = params.transactionStatus;
    this.autoCreditUrl = params.autoCreditUrl;

    this.currentAutoCreditValue = null;

    if (params.ga) {
      this.ga = params.ga;
    } else {
      this.ga = new Ga();
    }
    this.gaLocation = params.gaLocation;
    this.ga.sendVirtualPageView(this.gaLocation);

    this.initEvents();

    this.updateAutoCreditValue();

  }

  sendToMixpanel(event, properties) {
    if (this.mps)  {
      this.mps.send(event, properties);
    }
  }

  initEvents() {
    $("[data-auto-credit]").change(event => this.updatePrepaidAccountAutoCredit());

    var defaultOffer = window.document.querySelector("[data-offer]:checked");

    if (defaultOffer) {
      this.displayPaymentForm(defaultOffer);
    }

    window.document.querySelectorAll("[data-offer]").forEach((element) => {

      element.addEventListener("click", (event) => {
        this.displayPaymentForm(element);
      });

    });
  }

  displayPaymentForm(selectedOffer) {
    const offerId = selectedOffer.dataset.offer;
    const amount = selectedOffer.dataset.paymentAmount;
    const amountInMinorUnits = selectedOffer.dataset.paymentAmountInMinorUnits;
    const currency = selectedOffer.dataset.paymentCurrency || "EUR";

    this.updateCreditSummary();
    this.updateAutoCreditValue();
    this.updatePrepaidAccountAutoCredit();

    window.document.querySelectorAll("[data-payment-backend-provider]").forEach((paymentBackend) => {
      if (paymentBackend.dataset.paymentBackendOffers.indexOf("|" + offerId + "|") !== -1) {
        paymentBackend.style.display = paymentBackend.dataset.displayMode || "block";
      } else {
        paymentBackend.style.display = "none";
      }

      const backend = paymentBackend.dataset.paymentBackendProvider;
      const urlParams = selectedOffer.dataset.paymentParams;

      this.paymentBackendsManager.load(backend, {
        urlParams: urlParams,
        autoPost: false,
        amount: amount,
        amountInMinorUnits: amountInMinorUnits,
        currency: currency
      });
    });
  }

  updatePrepaidAccountAutoCredit({redirectUrl = null} = {}) {
    if (this.autoCreditUrl && this.$autoCredit) {
      let isChecked = this.$autoCredit.is(':checked');
      let value = this.$autoCredit.val();
      let newValue = null

      if (isChecked) {
        newValue = value;
        this.sendToMixpanel('Prepaid Account Auto Credit', {'enabled': true});
      } else {
        this.sendToMixpanel('Prepaid Account Auto Credit', {'enabled': false});
      }

      if (newValue !== this.currentAutoCreditValue) {
        this.currentAutoCreditValue = newValue;

        $.post(this.autoCreditUrl, {enabled: isChecked, value: value}).done(response => {
          if (redirectUrl) {
            document.location.href = redirectUrl;
          }
        });
      }
    }
  }

  updateAutoCreditValue() {
    let value = $('[data-offer]:checked').data('auto-credit-amount');
    this.$autoCredit.val(value);
    $('[data-auto-credit-value]').text(value);
  }


  updateCreditSummary() {
    const $selectedOffer = $("[data-offer]:checked");
    const currentBalance = $("[data-current-balance]").data("current-balance");
    const selectedOfferAmount = $selectedOffer.data("charged-amount");
    const selectedOfferFreeAmount = $selectedOffer.data("free-amount");
    const currentProductPrice = $("[data-current-product-price]").data("current-product-price") || 0;

    const currency = $selectedOffer.data("currency") || "â¬";

    const creditSummary = {
      'current_balance': currentBalance,
      'selected_offer_amount': selectedOfferAmount,
      'selected_offer_free_amount': selectedOfferFreeAmount,
      'current_product_price': currentProductPrice,
      'currency':  $selectedOffer.data("payment-currency") || "â¬",
    };

    this.sendToMixpanel("Change Amount To Credit", creditSummary);

    let newBalance = parseFloat(currentBalance) + parseFloat(selectedOfferAmount) + parseFloat(selectedOfferFreeAmount) - parseFloat(currentProductPrice);

    $("[data-selected-offer-amount]").html(`${selectedOfferAmount} ${currency}`);
    $("[data-selected-offer-free-amount]").html(`${selectedOfferFreeAmount} ${currency}`);

    $("#new-user-offer").toggle(!(parseFloat(selectedOfferFreeAmount) === 0.0));
    if (selectedOfferFreeAmount > 1) {
      $("[data-welcome-offer-text=plural]").show();
      $("[data-welcome-offer-text=singular]").hide();
    } else {
      $("[data-welcome-offer-text=plural]").hide();
      $("[data-welcome-offer-text=singular]").show();
    }

    $("[data-new-balance]").html(`${mathsutils.prettyFloat(newBalance, 2)} ${currency}`);

    $("[data-selected-offer-name]").text($(`label[for=${$selectedOffer.attr("id")}]`).find("h3").text());
  }
}
