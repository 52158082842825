import {PaymentBackend} from "./PaymentBackend";
import {loader} from "../utils/Loading";
import {disabler} from "../utils/Disabler";

export class ExternalBackend extends PaymentBackend {
  constructor(backend, params) {
    super();

    this.backend = backend;

    this.$container = $(`#${backend}-form-container`);

    this.autoPost = params.autoPost === true;

    this.paymentFallbackContainer = document.querySelector(`[data-payment-fallback]`);

    if (this.theme !== "dark" && this.theme !== "light") {
      this.theme = "light";
    }

    if (this.$container.length > 0) {
      this.formId = params.formId || `${backend}-form`;
      this.$form = $(`form#${this.formId}`);
      this.form = this.$form[0];


      this.transactionStatus = params.transactionStatus;
      this.startCheckTransaction = params.startCheckTransaction !== false;

      this._formSubmitEnabled = true;

      this.initEvents();

      if (this.autoPost) {
        this.$form.submit();
      }

    }
  }

  initEvents() {

    this.$form.handleLightAjaxSubmit({
      beforeSubmit: () => {
        loader.show(`${this.formId}-submit`);
        this._formSubmitEnabled = false;

        if (this.paymentFallbackContainer) {
          this.paymentFallbackContainer.style.display = "none";
        }
      },
      submitAllowed: (callback) => {
        callback(this._formSubmitEnabled);
      },
      onResponse: (success, data) => {
        this._formSubmitEnabled = !success;

        loader.hide("payment-loader");
        loader.hide(`${this.formId}-submit`);

        if (success) {
          loader.show('main');
        } else {

          this._formSubmitEnabled = true;

          if (this.paymentFallbackContainer) {
            if (data.fallback) {
              this.paymentFallbackContainer.style.display = this.paymentFallbackContainer.dataset.displayMode || "block";
            } else {
              this.paymentFallbackContainer.style.display = "none";
            }
          }

          this.onFormResponseError();
        }
      },
      onError: (jqXHR, textStatus, errorThrown) => {
        this._formSubmitEnabled = true;
        loader.hide("payment-loader");
        loader.hide(`${this.formId}-submit`);
        this.onFormResponseError();
      },
    });
  }

  onFormResponseError() {

    loader.hide("payment-loader");
    loader.hide(`${this.formId}-submit`);
    disabler.enable(`${this.formId}-disabler`);
    disabler.enable("payment-option-disabler");
  }
}
