import {SelectHandler, SimpleAjaxFormWorkflow} from "../../utils";

export class UserBillingDataWorkflow extends SimpleAjaxFormWorkflow {

  constructor(formId, translations = {}) {
    super(formId);

    this.$form.find("input[type=number]").on("keydown", (event) => {
      return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key));
    });

    new SelectHandler({
      $container: this.$form,
      translations: {
        "noResults": translations["noResults"],
      }
    });
  }

}
