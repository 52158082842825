import {messages} from "../utils/Messaging";
import {Ga, RemoteChecker} from "../utils";

export class PaymentFinalizedWorkFlow {

  constructor(params) {
    this.sendEcommerceData(params);
    this.redirect(params);
  }

  sendEcommerceData(params) {
    if (params.transactions) {
      const ga = new Ga({getRemoteTracker: false});

      ga.getRemoteTracker().then(() => {
        $.each(params.transactions, (i, data) => {
          ga.sendEcommerceData(data.transaction, data.item);
        });
        ga.clearCookies();
      });
    }

    if (params.contentSquareData) {
      window._uxa = window._uxa || [];

      if (typeof CS_CONF === "undefined") {
        window._uxa.push(["setPath", window.location.pathname + window.location.hash.replace('#', '?__')]);
        var mt = document.createElement("script");
        mt.type = "text/javascript";
        mt.async = true;
        mt.src = "//t.contentsquare.net/uxa/" + params.contentSquareData.tagId + ".js";
        document.getElementsByTagName("head")[0].appendChild(mt);
      }

      window._uxa.push(["ecommerce:addTransaction", {
        "id": params.contentSquareData.id,
        "revenue": params.contentSquareData.revenue,
        "shipping": params.contentSquareData.shipping,
        "tax": params.contentSquareData.tax
      }]);

      window._uxa.push(["ecommerce:send"]);
    }
  }

  redirect(params) {
    let messageParams = {};

    if (params.transactionId) {
      messageParams["transaction"] = params.transactionId;
    }

    messages.sendMessage("payment-finalized", {target: "top", params: messageParams});

    if (params.checkTransactionUrl) {

      this.transactionChecker = new RemoteChecker(params.checkTransactionUrl, 500, true);

      if (!this.transactionChecker.isStarted) {
        this.transactionChecker.start().then((response) => {

          if (response["ecommerce"]) {
            this.sendEcommerceData(response["ecommerce"]);
          }

          document.location.href = response.url;
        }, (d) => {

        });
      }
    }
  }
}
