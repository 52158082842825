class MathsUtils {

  prettyFloat(number, round=2) {
    let number_modf = this.modf(number, round);

    if (number_modf['frac'] === 0.0) {
      return number_modf['int'];
    } else {
      return `${number_modf['int']}.${number_modf['fracString']}`;
    }
  }

  modf(number, round=2) {

    let int = 0;
    let frac = 0;

    if (number > 0) {
      frac = math.abs(number) - math.floor(math.abs(number));
      int = math.floor(number);
    } else {
      frac = math.abs(number) - math.floor(math.abs(number));
      int = math.ceil(number);
    }

    let roundedFrac = math.round(frac, round);
    let fracString = roundedFrac.toString().split('.')[1];

    if (fracString) {
      fracString = this._padEnd(round, fracString, "0");
    }

    return {
      'int': int,
      'frac': roundedFrac,
      'fracString': fracString
    }
  }

  _padEnd(width, string, padding) {
    return (width <= string.length) ? string : this._padEnd(width, string + padding, padding);
  }
}

const mathsutils = new MathsUtils();

export {mathsutils};
