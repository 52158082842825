import {loader} from "../utils/Loading";

export class PayPalWorkflow {
  constructor(params) {

    this.environment = params.environment;
    this.selector = "#paypal-button";
    this.$errorContainer = $('#paypal-error-container');
    this.$loadErrorContainer = $('#paypal-not-loaded-container');
    this.$buttonContainer = $('#paypal-button-container');
    this.translation = params.translation;

    this.createPaymentUrl = $("[data-payment-option-container=paypal]").data("payment-option-url");
    this.executePaymentUrl = null;

    this.transactionStatus = params.transactionStatus;
    this.startCheckTransaction = params.startCheckTransaction !== false;

    if ($(this.selector).length > 0) {
      this.load();
    }

  }

  load() {
    if (typeof(paypal) !== 'undefined') {
      loader.show("paypal");
      this.render();
    } else {
      this.displayErrorPayPalNotLoaded();
    }
  }

  displayErrorPayPalNotLoaded() {
    this.$buttonContainer.hide();
    this.$loadErrorContainer.show();
  }

  render() {
    // https://developer.paypal.com/docs/checkout/how-to/customize-button/
    paypal.Button.render({
      env: this.environment,
      style: {
        tagline: true,
        label: 'paypal',  // checkout | credit | pay | buynow |Â paypal | installment
        size: 'responsive',    // small | medium | large | responsive
        shape: 'pill',     // pill | rect
        color: 'silver'      // gold | blue | silver | black | white
      },
      payment: (data, actions) => {
        loader.show("main");

        return actions.request.post(this.createPaymentUrl)
          .then((res) => {
            this.executePaymentUrl = res["redirect_url"];
            if (!res.success) {
              loader.hide("main");
              this.onError(res.error);
            }
            return res.id;
          });
      },
      onAuthorize: (data, actions) => {
        return actions.request.post(this.executePaymentUrl, {
          paymentID: data.paymentID,
          payerID: data.payerID
        })
          .then((res) => {
            if (res.success) {
              this.transactionStatus.addTransaction(res.transaction);

              if (!this.transactionStatus.isStarted) {
                this.transactionStatus.start().then(response => {
                  document.location.href = response['url'];
                });
              }
            } else {
              loader.hide("main");
              this.onError(res.error);
            }
          });
      },
      onCancel: (data, actions) => {
        loader.hide("main");
      },
    }, this.selector).then(() => {
      loader.hide("paypal");
    });
  }

  onError(message) {
    this.$errorContainer.show();
    if (message) {
      this.$errorContainer.html(message);
    } else {
      this.$errorContainer.html(this.translation["error-default"]);
    }
  }
}
