import {loader} from "../../utils/Loading";
import {SimpleAjaxFormWorkflow} from "../../utils";

export class TransactionRegisterWorkFlow extends SimpleAjaxFormWorkflow {

  constructor() {
    super("authorize-cb-form");
  }

  onFormSuccess(data) {
    loader.show('main');
    if ($('input[name=authorized]:checked').length > 0) {
      this.ga.sendVirtualPageView('authorize-credit-card');
    }
  }

}
