
export class CancellationWorkflow {
  constructor() {
    this.$form = $('form#cancel-subscription-form');

    this.initEvents();
  }

  initEvents() {
    this.$form.handleLightAjaxSubmit({
      onResponse: (success, data) => {
        if (success) {
          $('[data-form-success-container]').show();
          this.$form.hide();
        }
      }
    });

    $('[data-date-text-input]').bind('keyup', 'keydown', event => {
      let inputLength = event.target.value.length;
      if (event.keyCode != 8 && event.keyCode != 46) {
        let thisVal = event.target.value;

        if (inputLength === 2) {
          thisVal += '/';
          $(event.target).val(thisVal);
        } else if (inputLength > 2 && thisVal.indexOf("/") === -1) {
          thisVal = thisVal.replace("/", "");
          $(event.target).val([thisVal.slice(0, 2), "/", thisVal.slice(2)].join(""));
        }
      }
    });
  }
}
