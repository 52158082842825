import {RemoteChecker} from "../utils";


export class TransactionChecker extends RemoteChecker {

  constructor(url, interval = 2000) {
    super(url, interval);
    this.interval = interval;
    this._url = url;
    this._promise = null;
    this._handler = null;

    this._transactions = [];

  }

  getUrl(transaction) {
    if (this.url.indexOf("?") >= 0) {
      return `${this._url}&transaction=${transaction}`;
    } else {
      return `${this._url}?transaction=${transaction}`;
    }
  }

  check(resolve, reject) {
    for (let transaction of this._transactions) {
      $.get(this.getUrl(transaction)).done(response => {
        if (response.success) {
          resolve(response);
          this.stop();
        }
      });
    }
  }

  addTransaction(transaction) {
    this._transactions.push(transaction);
  }

}
