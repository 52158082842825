import mixpanel from "mixpanel-browser";

export class MpServices{

    constructor() {
      try {
        if (window.mxToken && window.mxUrl) {
          mixpanel.init(window.mxToken, {
            api_host: window.mxUrl,
          });
          const userId = document.body.getAttribute("mp-user-id");
          if (userId && mixpanel.get_distinct_id() !== userId) {
            mixpanel.identify(userId);
          }
        }
      } catch (e) {
        console.error("Cannot initialize mixpanel.");
      }

    }

    send(event, properties) {
      try {
        if(document.body.querySelector('mx-elt') !== null){
          const mx_default_datas_session = JSON.parse(Buffer.from(document.body.querySelector('mx-elt').getAttribute('mp-content'), 'base64').toString());
          if (properties === undefined) {
            properties = {};
          }
          properties = Object.assign(properties,mx_default_datas_session);
        }
        mixpanel.track(event, properties);
      } catch (e) {
        console.warn(e);
      }
    }

    giveProperties(elHtml) {
      return Array.from(elHtml.getAttributeNames()).reduce((res, key) => {
        if (key.match(/^mp-/)) {
          const target = key.replace('mp-', '').replace(/-/g, '_');
          if(elHtml.getAttribute(key)) {
            res[target] = elHtml.getAttribute(key);
          }
          else{
            res[target] = '';
          }
        }
        return res;
      }, {});
    }

    initMps() {
      if(document.body.querySelector('mx-elt') !== null){

        // Page View
        const pageView = document.body.getAttribute('mpe');
        if (pageView && pageView === 'Page View') {
          const properties = this.giveProperties(document.body);
          this.send(pageView, properties);
        }
        // Clicks
        document.body.addEventListener('click', (evt) => {
          const target = evt.target;
          const targetElement= target.hasAttribute('mpe') ? target : target.closest('[mpe]');
          const event = targetElement.getAttribute('mpe');
          if (targetElement && event && event !== 'Page View') {
            const properties = this.giveProperties(targetElement);
            this.send(event, properties);
          }
        });
      }

    }
}

