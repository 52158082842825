import {loader} from "../utils/Loading";

export class VideosStats {

  constructor() {
    this.form = document.forms["filters-form"];
    this.timeout = null;
    this.filtersEnabled = true;
    this.clearing = false;

    $(`[data-list-filter="select2"]`).on("select2:select", (event) => {
      this.onFiltersUpdate();
    });

    $(`[data-list-filter="select2"]`).on("select2:unselecting", (event) => {
      this.clearing = true;
    });

    $(`[data-list-filter="select2"]`).on("select2:opening", (event) => {
      if (this.clearing) {
        this.clearing = false;
        this.onFiltersUpdate();
      } else {
        this.clearTimeout();
      }
    });
  }

  clearTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

  onFiltersUpdate() {
    this.clearTimeout();

    if (this.filtersEnabled) {
      this.timeout = setTimeout(() => {
        this.redirect();
      }, 1000);
    }
  }

  get urlParams() {
    const formData = new FormData(this.form);
    const res = [];

    for (let pair of formData.entries()) {
      const key = pair[0];
      const value = pair[1];
      if (value && value !== "none") {
        res.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
      }
    }

    return res.join("&");
  }

  redirect(url) {
    this.filtersEnabled = false;

    if (!url) {
      url = this.form.action;
    }

    loader.show("filters-update");
    const params = this.urlParams;
    if (params) {
      url = url + "?" + params;
    }
    window.location.href = url;
  }
}