export class ResetPasswordWorkFlow {

    constructor() {
        this.$form = $("form#reset-password-form");
        this.initEvents();
    }

    initEvents() {
        this.$form.handleLightAjaxSubmit();
    }
}
