export class UploadInvoiceWorkflow {
  constructor() {
    this.$form = $('#upload-invoice-form');

    this.initEvents();
  }

  initEvents() {
    this.$form.handleAjaxSubmit();

    let ajaxForm = this.$form.data('ajaxForm');

    this.$form.find('input[type=file]').manageFileUpload({
      multipleFiles: false,
      ajaxForm: ajaxForm,
      ignore: false,
      onAddFile: function (data) {
        $('[data-selected-file=id_file]').val(data.files[0].name);
        return 'file';
      }
    });

    $('[data-select-period]').change(event => {
      let $currentTarget = $(event.currentTarget);
      $('[data-expected-amount-value]').html($currentTarget.find(':selected').data('expected-amount'));
    });
  }
}
