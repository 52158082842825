import {Ga} from "../utils";
import {TransactionChecker} from "./TransactionChecker";


export class Process3DSecure {

  constructor(params) {
    this.transactionStatus = new TransactionChecker(params.transactionUrl);
    this.transactionStatus.addTransaction(params.transaction);

    this.transactionStatus.start().then(response => {
      document.location.href = response.url;
    });

    this.ga = new Ga();
    this.gaLocation = params.gaLocation;
    this.ga.sendVirtualPageView(this.gaLocation);
  }

}

