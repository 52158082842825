export class PaymentBackend {

  constructor() {
    this.form = null;
    this.amount = null;
    this.amountInMinorUnits = null;
    this.currency = null;
  }

  init(params) {
    this.amount = params.amount;
    this.amountInMinorUnits = params.amountInMinorUnits;
    this.currency = params.currency;

    if (params.urlParams && this.form) {
      const url = this.form.action || this.form.dataset.basePaymentUrl;
      this.form.action = url.split("?")[0] + "?" + params.urlParams;
    }
  }

  get3DSecureParams() {
    if (!this.amountInMinorUnits) {
      const element = window.document.querySelector("[data-payment-option-amount-minor-units]");

      if (element) {
        this.amountInMinorUnits = element.dataset.paymentOptionAmountMinorUnits;
        this.currency = element.dataset.paymentOptionCurrency;
      }
    }

    return {
      "amount": this.amountInMinorUnits || "0",
      "currency": this.currency || "EUR"
    };
  }

  initClientFields() {
    if (this.form) {
      if (this.form.elements["clijava"]) {
        this.form.elements["clijava"].value = navigator.javaEnabled() ? "yes" : "no";
      }

      if (this.form.elements["cliscd"]) {
        this.form.elements["cliscd"].value = screen.colorDepth;
      }

      if (this.form.elements["clish"]) {
        this.form.elements["clish"].value = screen.height;
      }

      if (this.form.elements["clisw"]) {
        this.form.elements["clisw"].value = screen.width;
      }

      try {
        this.form.elements["timezone"].value = Intl.DateTimeFormat().resolvedOptions().timeZone;
      } catch (e) {
      }

      if (this.form.elements["tzoffset"]) {
        this.form.elements["tzoffset"].value = new Date().getTimezoneOffset();
      }

      if (this.form.elements["clilang"] && navigator.language) {
        this.form.elements["clilang"].value = navigator.language;
      }
    }
  }
}