export class EditSubscriptionWorkflow {
  constructor() {
    this.$form = $('form#user-subscription-edit-form');

    this.$subscriptonStatusAlert = $('[data-subscription-activation-status-alert]');
    this.$editSubscriptonStatusButton = $('[data-activate-subscription]');

    this.$editCreditCardButton = $('[data-edit-associated-credit-card]');
    this.$editCreditCardContainer = $('[data-edit-associated-credit-card-container]');

    this.initEvents();
  }

  initEvents() {
    this.$editSubscriptonStatusButton.click(event => {
      event.preventDefault();
      this.$subscriptonStatusAlert.show();
      this.$editSubscriptonStatusButton.hide();
    });

    $('[data-cancel-subscription-activation-status]').click(event => {
      event.preventDefault();
      this.$subscriptonStatusAlert.hide();
      this.$editSubscriptonStatusButton.show();
    });

    this.$editCreditCardButton.click(event => {
      event.preventDefault();
      this.$editCreditCardButton.hide();
      this.$editCreditCardContainer.show();
    });

  }
}
