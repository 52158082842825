import {loader} from "../utils/Loading";
import {disabler} from "../utils/Disabler";

export class CrossSellWorkflow {

  constructor(paymentUrl, redirectUrl) {
    this.paymentUrl = paymentUrl;
    this.redirectUrl = redirectUrl;

    if (!this.paymentUrl || !this.redirectUrl) {
      throw new Error("No payment or redirect url for cross sell view");
    }

    const $close = $("[data-close-iframe]");

    if ($close && $close.length > 0) {
      $close.attr("data-redirect", $("[data-no-thanks]").attr("href"));
    }

    $("[data-submit-payment]").click((event) => {
      event.preventDefault();

      loader.show("main");
      loader.show("submit-payment");
      disabler.disable("cross-sell");

      this.$error.hide();
      this.$continue.hide();

      $.ajax({
        type: "POST",
        url: this.paymentUrl,
        success: (response) => {
          if (response["success"]) {
            this.onPaymentSuccess();
          } else {
            this.onPaymentError(response);
          }
        },
        error: (data) => {
          this.onPaymentError(data);
        }
      });

    });
  }

  onPaymentError(data) {
    loader.hide("main");
    loader.hide("submit-payment");
    disabler.enable("cross-sell");

    let error = "";

    if (data["error"] && data["error"]["message"]) {
      error = data["error"]["message"];
    } else {
      error = $("[data-default-error]").data("default-error");
    }

    this.$error.find("span").html(error);
    this.$error.show();
    this.$continue.show();
    this.$initialButtons.hide();
  }

  onPaymentSuccess() {
    window.location.href = this.redirectUrl;
  }

  get $error() {
    return $("[data-container=error]");
  }

  get $continue() {
    return $("[data-container=continue-button]");
  }

  get $initialButtons() {
    return $("[data-container=initial-buttons]");
  }
}
