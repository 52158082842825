import {loader} from "../utils/Loading";
import {Ga, ReCaptchaManager, SimpleAjaxFormWorkflow} from "../utils";

export class TransactionRegisterWorkFlow extends SimpleAjaxFormWorkflow {

  constructor(params) {
    super("authorize-cb-form");
    this.register = params.register;
    this.$success = this.$form.find('[data-form-success-container]');

    $("body").on("click", "[data-user-create-confirm]", (event) => {
      event.preventDefault();
      $.get($(event.currentTarget).attr('href')).done(response => {
        if (response.success) {
          this.displaySuccessContainer(response.message);
        }
      });
    });

    const $close = $("[data-close-iframe]");

    if ($close && $close.length > 0) {
      $close.attr("data-redirect", $("[data-no-thanks]").attr("href"));
    }

    this.ga = new Ga();
    this.ga.sendVirtualPageView('transaction-register');

    if (this.$form && this.$form[0] && this.$form[0].elements["captcha"]) {
      this.captchaManager = new ReCaptchaManager({
        inputName: "captcha",
      });
    }
  }

  onFormError(data, response) {
    if (this.captchaManager) {
      this.captchaManager.init();
    }
  }

  onAjaxError() {
    if (this.captchaManager) {
      this.captchaManager.init();
    }
  }

  onFormSuccess(data) {
    loader.show('main');
    if ($('input[name=authorized]:checked').length > 0) {
      this.ga.sendVirtualPageView('authorize-credit-card');
    }
  }

  displaySuccessContainer(content) {
    this.$form.cleanFormErrors();
    let alert_html = `<div class="alert alert-success" role="alert" data-role="form-success">${content}</div>`;
    this.$success.append(alert_html);
    this.$success.show();
  }

}
