export class UserCreateWorkFlow {

    constructor() {
        this.$form = $('form#create-user-form');
        this.initEvents();
    }

    initEvents() {
      this.$form.handleLightAjaxSubmit();
    }
}
