export class OfferSelector {
  
  constructor() {
    $('[data-offer-selector]').change(event => {
      let value = $(event.currentTarget).val();
      $('[data-option-for-offer]').hide();
      $(`[data-option-for-offer="${value}"] input[type=radio]:first`).click();
      $(`[data-option-for-offer="${value}"]`).show();
    });
  }

  get selectedOffer() {
    return $('[data-offer-selector]:checked').val();
  }
  
}