export class PaymentsCbChooseSave{
    constructor(params){
        this.mps = params.mps;
        $("#id_save_card").change(event => this.update_save_card());
    }

    sendToMixpanel(event, properties) {
        if (this.mps)  {
            this.mps.send(event, properties);
        }
    }

    update_save_card() {
        var is_save_card = $("#id_save_card").is(':checked');
        this.sendToMixpanel('Save Credit Card', {'enabled': is_save_card});
    }
}