import {modal} from "../utils/Modal";

export class CreditCardWorkflow {

  constructor() {
    this.initEvents();
  }

  initEvents() {
    $('[data-set-cb-default]').click(event => this.loadModal(event));
    $('[data-delete-cb]').click(event => this.loadModal(event));
  }

  loadModal(event) {
    event.preventDefault();
    modal.load($(event.currentTarget).attr('href'), $modal => {
      $modal.filter('.modal').first().modal('show');
      $modal.find('form').handleLightAjaxSubmit();
    });
  }
}
