import {ExternalBackend} from "./ExternalBackend";

export class CCBillWorkflow extends ExternalBackend {

  constructor(params) {
    super("ccbill", params);
  }


}

