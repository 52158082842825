export class MagicLinkComplete {

    constructor(params) {
        this.next = params.next;
        this.countDown = params.countDown;

        this.initEvents();

        this.countDown.start(_ => {
            document.location.href = this.next;
        });
    }

    initEvents() {
        $('#connection-form').handleLightAjaxSubmit();
    }

}
